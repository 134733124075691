import { isAndroid } from "@/util/platform";

const FRONTEND_PREFIX = "/frontend";

const filterRoutes = routes => {
  return routes.filter(
    route => route.path.startsWith("/") && !route.meta?.excludeRedirect
  );
};

export const createRedirect = path => {
  return (
    !isAndroid && {
      path: `${FRONTEND_PREFIX}${path}`,
      redirect: () => ({ path })
    }
  );
};

const addPrefixAlias = route => {
  const shouldAddAlias =
    isAndroid && route.path.startsWith("/") && !route.meta?.excludeAlias;

  if (shouldAddAlias) {
    route.alias ||= [];
    route.alias.push(`${FRONTEND_PREFIX}${route.path}`);
  }

  return route;
};

const frontendRedirects = routes => {
  const filteredRoutes = filterRoutes(routes);

  return filteredRoutes.map(({ path }) => createRedirect(path)).filter(Boolean);
};

const androidAliases = routes => routes.map(route => addPrefixAlias(route));

export const frontendPrefixRoutes = routes => [
  ...frontendRedirects(routes),
  ...androidAliases(routes)
];

import * as Sentry from "@sentry/vue";
import { Vue as VueIntegration } from "@sentry/integrations";
import axios from "axios";
import { isArray } from "lodash";

const APP_TYPE = "STANDARD";
const ENVIRONMENT_URLS = {
  DEV: "app-dev",
  TEST: "app-test",
  PROD: "app"
};

// Get environment from URL at runtime
const getEnvironment = url => {
  let environment = "UNKNOWN";

  for (const [env, urlPart] of Object.entries(ENVIRONMENT_URLS)) {
    if (url.includes(`${urlPart}.point`)) {
      environment = env;
      break;
    }
  }

  return environment;
};

const captureRequestError = error => {
  const response = error.response;
  const request = response.config;

  Sentry.withScope(scope => {
    // Set easily identifiable "request" tag to sentry
    scope.setTag("request", `${request.method.toUpperCase()} /${request.url}`);

    // Send detailed context info about the request
    scope.setContext("Request", {
      url: `${request.baseURL}/${request.url}`,
      method: `${request.method.toUpperCase()}`,
      headers: request.headers,
      data: request.data
    });

    // Send detailed context info about the response
    scope.setContext("Response", {
      status: response.status,
      text: response.statusText,
      headers: response.headers,
      data: response.data
    });

    // Capture the error
    Sentry.captureException(error);
  });

  return Promise.reject(error);
};

// Resolves any error type to Error object
export const resolveError = error => {
  error = isArray(error) && error.length === 1 ? error[0] : error;

  switch (typeof error) {
    case "undefined":
      return new Error("Unknown error: undefined");
    case "string":
      return new Error(error);
    case "object":
      if (error === null) {
        return new Error("Unknown error: null");
      } else if (error instanceof Error) {
        return error;
      } else if ("text" in error) {
        return new Error(error.text);
      } else {
        return new Error(JSON.stringify(error));
      }
    default:
      return new Error(`Unknown error: ${error}`);
  }
};

export const setupSentryInterceptor = () => {
  axios.interceptors.response.use(
    // On fullfilled
    response => response,
    // On rejected
    captureRequestError
  );
};

export const initSentry = Vue => {
  if (process.env.NODE_ENV !== "production") return;

  const dsn = process.env.VUE_APP_SENTRY_DSN;
  const url = window.location.href;

  Sentry.init({
    dsn,
    integrations: [
      new VueIntegration({ Vue, attachProps: true, logErrors: true })
    ],
    environment: getEnvironment(url)
  });

  Sentry.setTags({
    app_type: APP_TYPE
  });
};

export default Sentry;

import ApiService, { insertParams } from "@/util/api.service";

const ENDPOINTS = {
  GET_BATCH: "packets/receive_batches/{batchId}",
  GET_PARCELS: "parcel_receptions/{batchId}",
  CONFIRM_PARCELS: "parcel_receptions/{batchId}/confirm",
  GET_EXPEDITION: "expeditions/{expeditionId}",
  GET_INVENTORY: "inventories/{inventoryId}",
  COMPLETE_INVENTORY: "inventories/{inventoryId}/complete",
  CONFIRM_INVENTORY: "inventories/{inventoryId}/confirm",
  REPRINT_BATCH: "packets/receive_batches/{batchId}/print_receipt",
  REPRINT_EXPEDITION: "expeditions/{expeditionId}/print/expedition_list_label",
  REPRINT_INVENTORY: "inventories/{inventoryId}/print/missing_packets_label"
};

export class ScannedService extends ApiService {
  static getBatchPackets = batchId => {
    return this.get(insertParams(ENDPOINTS.GET_BATCH, { batchId }));
  };

  static getBatchParcels = batchId => {
    return this.get(insertParams(ENDPOINTS.GET_PARCELS, { batchId }));
  };

  static putParcelsConfirm = batchId => {
    return this.put(insertParams(ENDPOINTS.CONFIRM_PARCELS, { batchId }));
  };

  static getExpeditionPackets = expeditionId => {
    return this.get(insertParams(ENDPOINTS.GET_EXPEDITION, { expeditionId }));
  };

  static getInventoryPackets = inventoryId => {
    return this.get(insertParams(ENDPOINTS.GET_INVENTORY, { inventoryId }));
  };

  static putCompleteInventory = inventoryId => {
    return this.put(
      insertParams(ENDPOINTS.COMPLETE_INVENTORY, { inventoryId })
    );
  };

  static putConfirmInventory = inventoryId => {
    return this.put(insertParams(ENDPOINTS.CONFIRM_INVENTORY, { inventoryId }));
  };

  static postReprintBatch = batchId => {
    return this.post(insertParams(ENDPOINTS.REPRINT_BATCH, { batchId }));
  };

  static postReprintExpedition = expeditionId => {
    return this.post(
      insertParams(ENDPOINTS.REPRINT_EXPEDITION, { expeditionId })
    );
  };

  static postReprintInventoryLabel = inventoryId => {
    return this.post(
      insertParams(ENDPOINTS.REPRINT_INVENTORY, { inventoryId })
    );
  };
}

export default ScannedService;

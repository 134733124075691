export default function getActualLanguage() {
  const languages =
    navigator.languages === undefined
      ? [navigator.language]
      : navigator.languages;

  const language = languages[0].split("-")[0];

  return language;
}

import { isEmpty } from "lodash";
import { ScannedService } from "@/util/scanned.service";
import { useAction } from "@/util/store-helper";

const getDefaultState = () => {
  return {
    items: [],
    counts: null
  };
};

const state = getDefaultState();

const getters = {
  emptyItems: () => {
    return isEmpty(state.items);
  },

  // Returns `true` only if `counts.failed` is greater than 0
  hasFailedScans: () => {
    return !!state.counts?.failed;
  }
};

const actions = {
  async getScannedPackets({ commit, dispatch }, batchId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await ScannedService.getBatchPackets(batchId);
      const { packets: items } = response.data;

      commit("setItems", items);

      return response;
    });
  },

  async getScannedParcels({ commit, dispatch }, batchId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await ScannedService.getBatchParcels(batchId);
      const { parcel_reception_scans: items, counts } = response.data;

      commit("setItems", items);
      commit("setCounts", counts);

      return response;
    });
  },

  async confirmScannedParcels({ commit, dispatch }, batchId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await await ScannedService.putParcelsConfirm(batchId);
    });
  },

  async getExpeditionPackets({ commit, dispatch }, expeditionId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await ScannedService.getExpeditionPackets(expeditionId);
      const { packets: items } = response.data;

      commit("setItems", items);

      return response;
    });
  },

  async getInventoryPackets({ commit, dispatch }, inventoryId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      const response = await ScannedService.getInventoryPackets(inventoryId);
      const { inventory_scans: items, counts } = response.data;

      commit("setItems", items);
      commit("setCounts", counts);

      return response;
    });
  },

  async completeInventory({ commit, dispatch }, inventoryId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await ScannedService.putCompleteInventory(inventoryId);
    });
  },

  async confirmInventory({ commit, dispatch }, inventoryId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await ScannedService.putConfirmInventory(inventoryId);
    });
  },

  async reprintScannedPacketsLabels({ commit, dispatch }, batchId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await ScannedService.postReprintBatch(batchId);
    });
  },

  async reprintExpeditionList({ commit, dispatch }, expeditionId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await ScannedService.postReprintExpedition(expeditionId);
    });
  },

  async reprintInventoryLabel({ commit, dispatch }, inventoryId) {
    const { handleRequest } = useAction({ commit, dispatch });

    return handleRequest(async () => {
      return await ScannedService.postReprintInventoryLabel(inventoryId);
    });
  }
};

const mutations = {
  setItems(state, items) {
    state.items = items;
    this.commit("clearErrors");
  },

  setCounts(state, counts) {
    state.counts = counts;
    this.commit("clearErrors");
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
